<template>
	<transition name="fade">
		<div
			v-if="modelValue"
			:class="['modal', { 'modal--transparent': transparentBackground }]"
			:style="pinTopScreen()">
			<div class="modal__wrapper">
				<nuxt-icon
					v-if="closedCross"
					class="icon modal__close"
					name="dropdown-close"
					@click="hide()" />

				<slot />
			</div>

			<div
				v-if="closedOutside"
				class="modal__out-area"
				@click="hide()"></div>
		</div>
	</transition>
</template>

<script setup>
	const props = defineProps({
		modelValue: {
			type: Boolean,
		},
		closedOutside: {
			type: Boolean,
			required: false,
			default: true,
		},
		closedCross: {
			type: Boolean,
			required: false,
			default: true,
		},
		transparentBackground: {
			type: Boolean,
			required: false,
			default: false,
		},
	});
	const emits = defineEmits(["update:modelValue"]);
	const hide = () => emits("update:modelValue", false);

	watch(
		() => props.modelValue,
		(val) => {
			const body = document.querySelector("body");

			if (val) {
				body.classList.add("block-scroll");
			} else {
				body.classList.remove("block-scroll");
			}
		},
	);

	const pinTopScreen = () => `top: ${window.pageYOffset}px`;
</script>

<style scoped lang="scss">
	@import "modal";
</style>
